import { useIsLoggedInSelector, userActions } from '@/store/slices/user';
import { IAppCustomer } from '@/types/api/app/customer';
import { FilterBuilder } from '@loopback/filter';
import {
  createApi,
  fetchBaseQuery,
  retry,
  skipToken,
} from '@reduxjs/toolkit/query/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from '../slices/app';

export const APP_QUERY_TAG = {
  customerMe: 'customer-me',

  productVariants: 'product-variants',
  productVariantsCount: 'product-variants-count',
  products: 'products',
  productBySlug: 'product-by-slug',
  productsSearch: 'products-search',

  cart: 'cart',
  collections: 'collections',
  collectionsCount: 'collections-count',
  collectionDetails: 'collection-details',

  categories: 'categories',
  categoryDetails: 'category-details',

  customerDeliveryInfos: 'customer-delivery-infos',
  customerPaymentInfos: 'customer-payment-infos',
  customerOrders: 'customer-orders',
  customerOrdersCount: 'customer-orders-count',
  customerOrderDetails: 'customer-order-details',
  customerCartCheckout: 'customer-cart-checkout',

  customerAssets: 'customer-assets',
  customerAssetsMetrics: 'customer-assets-metrics',
  customerAssetsCount: 'customer-assets-count',

  metalPriceLatest: 'metal-price-latest',
  metalPriceTwoLatest: 'metal-price-two-latest',
  metalPriceOldest: 'metal-price-oldest',
  metalPrices: 'metal-prices',
  reviews: 'reviews',
  reviewsCount: 'reviews-count',

  inStockProductIds: 'in-stock-product-ids',
  tag: 'tag',
};

const appApis = createApi({
  reducerPath: 'appApis',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: '/api-handler',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
    {
      maxRetries: 0,
    }
  ),
  refetchOnReconnect: true,
  tagTypes: Object.values(APP_QUERY_TAG),
  endpoints: (build) => ({
    getLoggedCustomer: build.query<IAppCustomer, void>({
      query: () => {
        const filterBuilder = new FilterBuilder();
        filterBuilder
          .include({
            relation: 'defaultDeliveryInfo',
          })
          .include({
            relation: 'defaultPaymentInfo',
          });

        const filter = filterBuilder.build();

        return {
          url: '/customers/me',
          method: 'GET',
          params: {
            filter: JSON.stringify(filter),
          },
        };
      },
      providesTags: [APP_QUERY_TAG.customerMe],
    }),
  }),
});

export const { useGetLoggedCustomerQuery } = appApis;

export const useGetLoggedCustomer = () => {
  const isLoggedIn = useIsLoggedInSelector();
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError } = useGetLoggedCustomerQuery(
    isLoggedIn ? undefined : skipToken
  );

  useEffect(() => {
    if (!isError) return;
    dispatch(userActions.setIsLoggedIn(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return {
    loggedCustomer: data,
    isLoading: isLoading || isFetching,
  };
};

export default appApis;
