'use client';

import {
  Flex,
  Box,
  Text,
  Tooltip,
  FlexProps,
  TextProps,
} from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import Image, { ImageProps } from 'next/image';
import { ICartItem } from '@/types/cart';
import { LockLineIcon } from '@/components/Icons';
import colors from '@/constants/theme/colors';
import { SubtractLineIcon, AddLineIcon } from '@/components/Icons';
import { useDispatch } from 'react-redux';
import {
  appActions,
  useCurrencyCodeSelector,
  useCurrencyExchangeRateSelector,
} from '@/store/slices/app';
import { displayProductVariantMetal, formatPrice } from '@/utils/product';
import {
  useAddCartItemMutation,
  useDeleteCartItemMutation,
} from '@/store/apis/app.carts';
import { useIsLoggedInSelector } from '@/store/slices/user';
import { useGetGiftCardQuery } from '@/store/apis/app.products';
import { GIFTCARD_IMAGE_URL, GiftCardType } from '@/utils/gift-card';

interface CartItemProps {
  cartItem: ICartItem;
}
const CartItem: FC<CartItemProps> = ({ cartItem }) => {
  const {
    product,
    productVariant,
    quantity,
    subTotalPrice,
    id: cartItemId,
    metadata,
  } = cartItem;

  const dispatch = useDispatch();
  const selectedCurrencyCode = useCurrencyCodeSelector();
  const currencyExchangeRate = useCurrencyExchangeRateSelector();

  const [addCartItem, { isLoading: isAddCartItemLoading }] =
    useAddCartItemMutation();
  const [deleteCartItem, { isLoading: isDeleteCartItemLoading }] =
    useDeleteCartItemMutation();
  const isLoggedIn = useIsLoggedInSelector();
  const { data: giftCard } = useGetGiftCardQuery();

  const { price, priceCurrency, metal, size, photoOrVideoUrls } =
    productVariant ?? {};

  const currencyValue = selectedCurrencyCode ?? priceCurrency;
  const priceValue = (subTotalPrice ?? price) * currencyExchangeRate;

  const handleIncQty = useCallback(async () => {
    if (isLoggedIn) {
      await addCartItem({
        body: {
          id: cartItemId,
          productVariantId: productVariant.id,
          quantity: quantity + 1,
          metadata,
        },
      });
    }

    dispatch(
      appActions.incCartItemQty({
        cartProductItem: {
          product,
          productVariant,
          metadata,
        },
      })
    );
  }, [
    dispatch,
    product,
    productVariant,
    quantity,
    addCartItem,
    isLoggedIn,
    cartItemId,
    metadata,
  ]);

  const handleDecQty = useCallback(async () => {
    if (isLoggedIn) {
      if (quantity === 1) {
        await deleteCartItem({
          body: {},
          params: {
            cartItemId,
          },
        });
      } else {
        await addCartItem({
          body: {
            id: cartItemId,
            productVariantId: productVariant.id,
            quantity: quantity - 1,
            metadata,
          },
        });
      }
    }

    dispatch(
      appActions.decCartItemQty({
        cartProductItem: { product, productVariant, metadata },
      })
    );
  }, [
    dispatch,
    product,
    productVariant,
    quantity,
    addCartItem,
    isLoggedIn,
    deleteCartItem,
    cartItemId,
    metadata,
  ]);

  const { value: metalValue } = displayProductVariantMetal(metal);

  const isCartItemLoading = isDeleteCartItemLoading || isAddCartItemLoading;
  const isGiftCard = !!giftCard && giftCard.id === product.id;

  const _renderImage = () => {
    if (isGiftCard) {
      const { size: giftCardType } = productVariant as { size: GiftCardType };

      const imageUrl = GIFTCARD_IMAGE_URL[giftCardType];

      return (
        <Box w="72px" h="72px" position="relative" overflow="hidden">
          <Image
            alt="product"
            src={imageUrl}
            fill
            style={{
              objectFit: 'cover',
            }}
          />
        </Box>
      );
    }

    if (!!photoOrVideoUrls?.[0])
      return (
        <Box w="72px" h="72px" position="relative" overflow="hidden">
          <Image
            alt="product"
            fill
            style={{ objectFit: 'cover' }}
            src={photoOrVideoUrls[0]}
            unoptimized
          />
        </Box>
      );

    return (
      <Flex
        w="72px"
        h="72px"
        alignItems="center"
        justifyContent="center"
        bgColor={colors.grey[100]}
      >
        <Text
          color={colors.primary[300]}
          textTransform="uppercase"
          fontSize="0.675rem"
        >
          no image
        </Text>
      </Flex>
    );
  };

  const _renderTitle = () => {
    const props: TextProps = {
      color: colors.primary[300],
      lineHeight: '1.5rem',
      maxW: { base: '60vw', xl: '12rem' },
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };

    if (isGiftCard) {
      const { size: giftCardType } = productVariant as { size: GiftCardType };

      const title = `${giftCardType} Gift Card`;

      return <Text {...props}>{title}</Text>;
    }

    const { title } = product;
    const { title: variantTitle } = productVariant;

    return <Text {...props}>{variantTitle ?? title}</Text>;
  };

  const _renderSubtitle = () => {
    const props: TextProps = {
      color: colors.grey[500],
      fontSize: '0.875rem',
      fontWeight: '300',
      lineHeight: '1.25rem',
      display: 'flex',
    };

    if (isGiftCard) {
      if (!metadata) return null;
      const {
        recipientEmail = '',
        recipientName = '',
        message = '',
      } = metadata;

      return (
        <Flex flexDir="column" gap="0.5rem">
          <Text {...props}>{`${recipientName} (${recipientEmail})`}</Text>
          <Text {...props}>{`"${message}"`}</Text>
        </Flex>
      );
    }

    return (
      <Text {...props}>{`${metalValue}${!!size ? `, ${size}` : ''}`}</Text>
    );
  };

  return (
    <Flex gap="1rem">
      <Box
        position="relative"
        width="4.5rem"
        aspectRatio="1/1"
        overflow="hidden"
      >
        {_renderImage()}
      </Box>
      <Flex flexDir="column" gap="0.5rem" flex="1">
        <Flex flexDir="column">
          {_renderTitle()}
          {_renderSubtitle()}
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Box>
            <QuantityInput
              quantity={quantity}
              onInc={handleIncQty}
              onDec={handleDecQty}
            />
          </Box>
          <Tooltip
            hasArrow
            placement="bottom-start"
            label="Price locked while in cart, check out within 15 mins to secure the locked price."
          >
            <Flex alignItems="center" gap="0.25rem" cursor="pointer">
              <Text
                color={colors.primary[300]}
                fontSize="0.9375rem"
                lineHeight="1.25rem"
              >{`${formatPrice(currencyValue)(priceValue)}`}</Text>
              <LockLineIcon size="1rem" color={colors.primary[25]} />
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

interface QuantityInputProps {
  quantity: number;
  onInc?: () => void;
  onDec?: () => void;
  isDisabled?: boolean;
}
const QuantityInput: FC<QuantityInputProps> = ({
  quantity,
  isDisabled,
  onInc,
  onDec,
}) => {
  const actionContainerProps: FlexProps = {
    alignItems: 'center',
    justifyContent: 'center',
    p: '0.25rem',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  };

  return (
    <Flex
      w="fit-content"
      border={`1px solid ${colors.grey[200]}`}
      userSelect="none"
    >
      <Flex
        {...actionContainerProps}
        borderRight={`1px solid ${colors.grey[200]}`}
        onClick={() => {
          if (isDisabled) return;
          onDec?.();
        }}
      >
        <SubtractLineIcon size="0.75rem" color={colors.primary[300]} />
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Text
          color={colors.primary[300]}
          fontSize="0.75rem"
          fontWeight="300"
          lineHeight="1rem"
          px="0.75rem"
        >
          {quantity}
        </Text>
      </Flex>
      <Flex
        {...actionContainerProps}
        borderLeft={`1px solid ${colors.grey[200]}`}
        onClick={() => {
          if (isDisabled) return;
          onInc?.();
        }}
      >
        <AddLineIcon size="0.75rem" color={colors.primary[300]} />
      </Flex>
    </Flex>
  );
};

export default CartItem;
